@use '~@angular/material' as mat;
@import "./theme.scss";

// Include non-theme styles for core.
@include mat.core();

html,
body {
  min-height: 100%;
  display: flex;
  flex: 1;
  max-width: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
}
.space {
  flex: 1 1 auto;
}

.v-gap > *:not(:last-child) {
  margin-right: 1rem;
}

.h-gap {
  &-nl > *:not(:last-child),
  > * {
    margin-bottom: 1rem;
  }
}

@include mat.all-component-themes($theme);
